section.notice-edit {
  .popup-check-container {
    .popup-check-wrap {
      padding: 0 0 1.5rem;

      .popup-check-group {
        position: relative;
        display: flex;
        align-items: center;
      }

      input[type='checkbox']:checked ~ img {
        opacity: 1;
      }

      input[type='checkbox']:checked ~ label .box {
        background-color: rgba(0, 0, 0, 0);
      }

      input[type='checkbox']:checked ~ label span {
        color: rgba(0, 0, 0, 1);
      }

      img {
        position: absolute;
        top: -5px;
        z-index: 1;
        opacity: 0;
      }

      label {
        display: flex;
        align-items: center;
        cursor: pointer;

        .box {
          position: relative;
          z-index: 2;
          width: 15px;
          height: 15px;
          border: 1px solid rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.05);
        }

        span {
          font-size: 0.85em;
          padding-left: 0.7em;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
}
