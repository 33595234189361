.go_top {
    @media (min-width: 1024px) {
        position: fixed;
        right: 4rem;
        bottom: 3rem;
        z-index: 999;
        width: 60px;
        height: 60px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.9);
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -25%) rotate(45deg);
            width: 15px;
            height: 15px;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-left: 1px solid rgba(0, 0, 0, 0.2);
            transition: 0.4s;
        }

        &:hover {
            border: 1px solid rgba(0, 0, 0, 0.6);

            &::after {
                width: 18px;
                height: 18px;
                border-top: 1px solid rgba(0, 0, 0, 0.6);
                border-left: 1px solid rgba(0, 0, 0, 0.6);
            }
        }
    }
}
