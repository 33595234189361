.picture-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    transition: 0.5s;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 35px;
        height: 35px;
        background-image: url('/assets/images/admin/close-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.7;
        cursor: pointer;

        @media (min-width: 768px) {
            top: 2rem;
            right: 3rem;
            width: 40px;
            height: 40px;
        }

        @media (min-width: 1440px) {
            top: 3rem;
            right: 4rem;
            width: 55px;
            height: 55px;
        }

        &:hover {
            opacity: 1;
        }
    }

    .wrap {
        width: 100%;
        max-width: 768px;
        // border: 1px solid red;
        height: 300px;

        @media (min-width: 768px) {
            height: 60%;
            max-height: 568.8px;
        }

        .swiper-container {
            .swiper-slide {
                width: 100%;

                img {
                    display: inline-block;
                    width: 100%;
                    max-width: 768px;
                    height: 100%;
                    // max-width: 768px;
                    object-fit: cover;
                }
            }

            &.gallery-top {
                height: 80%;

                .swiper-button-prev,
                .swiper-button-next {
                    background-size: 50%;

                    @media (min-width: 768px) {
                        background-size: 80%;
                    }
                }

                .swiper-slide {
                    cursor: grab;

                    &:active {
                        cursor: grabbing;
                    }
                }
            }

            &.gallery-thumbs {
                height: 20%;
                box-sizing: border-box;
                padding-top: 10px;

                .swiper-slide {
                    width: 25%;
                    max-width: 192px;
                    opacity: 0.4;
                    cursor: pointer;
                }

                .swiper-slide-thumb-active {
                    opacity: 1;
                }
            }
        }
    }
}
