@import 'node_modules/reset-css/sass/_reset.scss';

h1 {
    font-size: 32px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 19px;
}

h5 {
    font-size: 13px;
}

h6 {
    font-size: 11px;
}