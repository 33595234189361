section.member-edit {
    article {
        max-width: 500px;
        font-size: 14px;

        @media (min-width: 768px) {
            font-size: 15px;
        }
    }
}
