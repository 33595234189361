article.calendar-container {
    position: relative;

    .wrap {
        width: 100%;

        h3 {
            text-align: center;
            padding-bottom: 1.5rem;
            color: $representColor;
            opacity: 0.7;

            @media (min-width: 768px) {
                padding-bottom: 2rem;
            }
        }

        .kind {
            padding: 0rem 0 2rem;
            display: flex;
            justify-content: center;
            font-size: 14px;

            @media (min-width: 768px) {
                font-size: 16px;
            }

            .bundle {
                width: 250px;
                display: flex;
                justify-content: space-between;

                @media (min-width: 768px) {
                    width: 300px;
                }

                p {
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    padding: 0.3rem 1rem;
                    cursor: pointer;

                    &.active {
                        border: 1px solid rgba(90, 75, 58, 0.5);

                        span {
                            &:first-child {
                                opacity: 1;
                            }

                            &:last-child {
                                color: rgba(90, 75, 58, 0.8);
                            }
                        }
                    }

                    span {
                        display: inline-block;
                        &:first-child {
                            width: 5px;
                            height: 5px;
                            background-color: $representColor;
                            border-radius: 50%;
                            margin-right: 0.3rem;
                            opacity: 0;
                        }

                        &:last-child {
                            color: rgba(90, 75, 58, 0.5);
                        }
                    }
                }
            }
        }

        ul {
            display: flex;
            margin-bottom: 1rem;
            color: rgba(0, 0, 0, 0.7);
            border-top: 1px solid rgba(90, 75, 58, 0.3);
            border-bottom: 1px solid rgba(90, 75, 58, 0.3);
            padding: 0.5rem 0;
            font-size: 14px;

            @media (min-width: 768px) {
                background-color: $representColor;
                border: 0;
                color: white;
                font-size: 16px;
            }

            li {
                flex: 1;
                text-align: center;
            }
        }

        .box {
            position: relative;
            font-size: 14px;

            @media (min-width: 768px) {
                font-size: 15px;
            }

            table {
                position: relative;
                z-index: 1;
                width: 100%;

                td {
                    position: relative;
                    border-top: 1px solid rgba(90, 75, 58, 0.3);
                    color: rgba(90, 75, 58, 0.7);
                    min-height: 50px;
                    height: 100px;

                    @media (min-width: 768px) {
                        border: 1px solid rgba(90, 75, 58, 0.3);
                    }

                    &:hover {
                        background-color: rgba(223, 249, 251, 0.4);
                        cursor: pointer;
                    }

                    span {
                        position: absolute;
                        top: 3px;
                        right: 50%;
                        transform: translateX(50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 22px;
                        height: 22px;
                        // padding: 0.25rem;

                        @media (min-width: 768px) {
                            right: 5px;
                            transform: translateX(0%);
                        }

                        &.bg-info {
                            border-radius: 50%;
                            background-color: rgba(90, 75, 58, 0.7);
                            color: white;
                        }
                    }

                    div {
                        position: absolute;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        width: 100%;
                        height: 70px;
                        overflow: hidden;
                        padding: 0.5rem 0.3rem;

                        @media (min-width: 768px) {
                            & {
                                bottom: 0;
                            }
                        }

                        p {
                            @include centered();
                            font-size: 14px;
                            margin-bottom: 0.2rem;
                            width: 8px;
                            height: 8px;
                            background-color: rgb(207, 223, 224);
                            color: rgba(0, 0, 0, 0);
                            // color: red;
                            border-radius: 50%;

                            &.hilltop {
                                background-color: #194663;
                            }

                            &:last-child {
                                margin: 0;
                            }

                            &::before {
                                content: '•';
                                padding: 0 0.3rem;
                            }

                            @media (min-width: 768px) {
                                & {
                                    position: relative;
                                    top: 0;
                                    left: 0;
                                    transform: translate(0, 0);
                                    display: flex;
                                    // align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    // height: 14px;
                                    height: 28px;
                                    background-color: rgba(0, 0, 0, 0);
                                    color: rgba(90, 75, 58, 0.7);
                                    border-radius: 0%;
                                    @include textellipsis(2);
                                    line-height: 1;

                                    &.hilltop {
                                        background-color: rgba(0, 0, 0, 0);
                                        color: #194663;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .button-wrap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-height: 500px;

                &.intro {
                    button {
                        @media (max-width: 1024px) {
                            top: -40px;
                        }
                        @media (max-width: 767px) {
                            top: -34px;
                        }
                    }
                }

                button {
                    border: none;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    background-color: rgba(90, 75, 58, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;

                    &#previous {
                        left: -7%;
                    }

                    &#next {
                        right: -7%;
                    }

                    @media (max-width: 1024px) {
                        top: -150px;

                        &#previous {
                            left: 38%;
                        }

                        &#next {
                            right: 38%;
                        }
                    }

                    @media (max-width: 768px) {
                        top: -150px;

                        &#previous {
                            left: 35%;
                        }

                        &#next {
                            right: 35%;
                        }
                    }

                    @media (max-width: 536px) {
                        top: -140px;

                        &#previous {
                            left: 22%;
                        }

                        &#next {
                            right: 22%;
                        }
                    }
                }
            }

            .pop-container {
                background-color: rgba(0, 0, 0, 0);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 0.5rem;

                .pop-wrap {
                    position: relative;
                    width: 100%;
                    max-width: 450px;
                    min-height: 140px;
                    max-height: 300px;
                    background-color: white;
                    border-radius: 8px;
                    overflow: hidden;
                    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
                    animation: appear 0.4s both;

                    @keyframes appear {
                        from {
                            transform: translate(0, 15px);
                        }
                        to {
                            transform: translate(0, 0);
                        }
                    }

                    .pop-title {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        font-size: 1.2rem;
                        color: rgba(90, 75, 58, 08);
                        border: 1px solid rgba(0, 0, 0, 0.1);

                        .pop-close {
                            position: absolute;
                            top: 50%;
                            right: 1rem;
                            transform: translateY(-50%);
                            background-image: url('/assets/images/admin/close.svg');
                            background-size: 100%;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 24px;
                            height: 24px;
                            opacity: 0.6;
                            cursor: pointer;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    .pop-contents {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        // justify-content: center;
                        align-items: center;
                        min-height: 100px;
                        max-height: 260px;
                        overflow-y: scroll;
                        padding: 0 2rem;
                        background-image: url('/assets/images/symbol.png');
                        background-repeat: no-repeat;
                        background-size: 50%;
                        background-position: 250px 30px;

                        // &:after {
                        //     content: '';
                        //     display: block;
                        //     width: 100%;
                        //     height: 100%;
                        //     position: fixed;
                        //     top: 0;
                        //     left: 0;
                        //     z-index: -1;
                        //     background-image: url('/assets/images/logo_main.png');
                        //     background-repeat: no-repeat;
                        //     background-size: 70%;
                        //     background-position: 220px 60px;
                        //     opacity: 0.1;
                        // }

                        @media (min-width: 768px) {
                            padding: 0 4rem;
                        }

                        p {
                            min-width: 260px;
                            margin-bottom: 2.25rem;
                            color: rgba(0, 0, 0, 0.7);

                            // &:last-child {
                            //     margin-bottom: 0;
                            // }
                            &:first-child {
                                margin-top: 2.25rem;
                            }

                            &::before {
                                content: '•';
                                padding: 0 0.3rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
