article.board {
    margin-top: 2rem;
    font-size: 14px;

    @media (min-width: 768px) {
        font-size: 16px;
    }

    form.filter {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .choose {
            display: flex;
            align-items: flex-end;

            p {
                display: flex;
                align-items: center;
                cursor: pointer;
                opacity: 0.5;

                span {
                    color: $representColor;
                    font-size: 13px;

                    &:first-child {
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        background-color: $representColor;
                        border-radius: 50%;
                        margin-right: 0.3rem;
                        opacity: 0;
                    }
                }

                &:first-child {
                    margin-right: 0.5rem;
                }

                &.active {
                    opacity: 1;

                    span {
                        &:first-child {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .search {
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            margin-bottom: -0.7rem;

            @include media(mobile-only) {
                & {
                    width: 50%;
                }
            }

            select {
                @include customSelect;
                // margin-right: 0.5rem;
                padding-left: 0;
                padding-right: 1.5rem;
                background-position: 100% 50%;
                font-size: 14px;

                @include media(tablet) {
                    & {
                        font-size: 15px;
                    }
                }
            }

            label {
                display: flex;

                input {
                    @include customInput;
                }

                input.text {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }

                input.submit {
                    background-image: url('https://icongr.am/feather/search.svg?size=20&color=cccccc');
                    background-repeat: no-repeat;
                    background-position: 100% 50%;
                    background-color: white;
                    cursor: pointer;
                }
            }

            // button[type='submit'] {
            //     @include customButton(#95afc0, #fff);
            //     border: 1px solid rgba(0, 0, 0, 0);
            //     padding: 0.5rem 3rem;
            //     display: none;

            //     @include media(tablet) {
            //         & {
            //             display: block;
            //         }
            //     }
            // }
        }
    }

    .list {
        padding: 1rem 0;

        .head,
        .body {
            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                background-color: rgba(252, 252, 251, 1);
                color: black;
                font-weight: bold;
                border-top: 1px solid black;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                // border-radius: 0.5rem 0.5rem 0 0;

                p.empty {
                    padding: 1rem 0;
                    cursor: default !important;
                }

                li {
                    text-align: center;
                    padding: 0.5rem 0;
                }

                @include media(mobile-only) {
                    & {
                        li:first-child {
                            width: 25%;
                        }
                        li:nth-child(2) {
                            width: 75%;
                            // order: 1;
                        }

                        li:nth-child(3) {
                            width: 25%;
                            // order: 2;
                        }

                        li:nth-child(4) {
                            width: 50%;
                            // order: 3;
                        }

                        li:last-child {
                            width: 25%;
                            // order: 6;
                        }
                    }
                }

                @include media(tablet) {
                    & {
                        li {
                            padding: 1rem 0;
                            flex: 1;
                        }

                        li:first-child,
                        li:nth-child(2) {
                            display: block;
                        }

                        li:nth-child(2) {
                            flex: 6;
                        }
                        li:nth-child(3) {
                            flex: 1;
                        }
                        li:nth-child(4) {
                            flex: 2;
                        }

                        li:last-child {
                            flex: 1;
                        }
                    }
                }

                @include media(desktop) {
                    & {
                        li {
                            padding: 1rem 0;
                        }

                        li:first-child {
                            display: block;
                            flex: 1;
                        }

                        li:nth-child(2) {
                            display: block;
                            flex: 6;
                        }

                        li:nth-child(3) {
                            flex: 1;
                        }

                        li:nth-child(4) {
                            flex: 2;
                        }

                        li:last-child {
                            flex: 1;
                        }
                    }
                }
            }
        }

        .body {
            ul {
                background-color: #fff;
                border-radius: initial;
                color: rgba(0, 0, 0, 0.64);
                border-top: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
                font-weight: 400;
                transition: 0.5s;

                &:hover {
                    color: rgba(0, 0, 0, 0.94);
                    // font-weight: 500;
                    // transform: translateY(-1px);
                    // box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
                    img {
                        opacity: 1 !important;
                    }
                }

                &:last-child {
                    border-bottom: 1px solid rgba(0, 0, 0, 1);
                }

                // input[type="checkbox"] {
                //     @include customCheckbox();
                // }
                li {
                    &:nth-child(2) {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            display: inline-block;
                            width: 16px;
                            margin-left: 5px;
                            opacity: 0.6;
                        }
                    }
                }
            }

            a {
                ul {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
                }
            }

            // .empty--data--box {
            //     padding: 10rem 0;
            //     @include flexCentered;
            // }
        }

        .footer {
            margin-top: 2rem;

            ul {
                display: flex;
                justify-content: center;
                padding: 0.57rem 0;

                li {
                    margin: 0.5rem;
                    cursor: pointer;
                    border-radius: 0.25rem;
                    opacity: 0.5;

                    &.active {
                        font-size: 1.15em;
                        opacity: 1;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
