section.admin-schedule {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
        flex-wrap: nowrap;
        overflow: hidden;
    }

    article {
        width: 100%;

        &.register {
            overflow-y: scroll;

            ul.swich-title {
                display: flex;
                font-size: 18px;
                padding: 3rem 1rem;

                @media (min-width: 768px) {
                    font-size: 20px;
                    padding: 5rem 2rem 3.5rem;
                }

                @media (min-width: 1024px) {
                    padding: 6rem 2rem 3.5rem;
                }

                @media (min-width: 1366px) {
                    font-size: 22px;
                    padding: 7rem 7.19rem 4rem;
                }

                @media (min-width: 1680px) {
                    max-width: 910px;
                    padding: 7rem 0 4rem;
                    margin-left: 10rem;
                }

                li {
                    &:first-child {
                        padding-right: 1rem;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                    }

                    &:last-child {
                        padding-left: 1rem;
                    }

                    a {
                        opacity: 0.5;
                        font-size: 0.95em;

                        &.active {
                            opacity: 1;
                            font-size: 1em;
                        }
                    }
                }
            }

            .view-calendar--wrap {
                @media (min-width: 768px) {
                    // padding: 0 1rem;
                }

                @media (min-width: 1366px) {
                    width: 400px;
                    margin: 0 auto;
                }

                @media (min-width: 1680px) {
                    margin-left: 10rem;
                }

                #calendar {
                    #calendar-body {
                        tr {
                            td {
                                height: 70px;

                                @media (min-width: 1366px) and (max-width: 1660px) {
                                    height: 55px;
                                }

                                &:hover {
                                    background-color: white;
                                    cursor: default;
                                }

                                span {
                                    font-size: 14px;
                                }

                                div {
                                    bottom: auto;

                                    p {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, 100%);
                                        font-size: 14px;
                                        margin-bottom: 0.2rem;
                                        width: 8px;
                                        height: 8px;
                                        background-color: #cfdfe0;
                                        color: rgba(0, 0, 0, 0);
                                        border-radius: 50%;

                                        @media (min-width: 1366px) and (max-width: 1660px) {
                                            transform: translate(-50%, 0%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .button-wrap {
                    button {
                        @media (max-width: 767px) {
                            top: -33px;
                        }

                        @media (min-width: 768px) {
                            top: -40px;
                            &#previous {
                                left: 22%;
                            }

                            &#next {
                                right: 22%;
                            }
                        }
                    }
                }
            }

            .add--container {
                display: flex;
                align-items: center;
                max-width: 400px;
                margin: 4rem auto 2rem;
                padding: 0 0.75rem;
                // border: 1px solid dodgerblue;

                @media (min-width: 1366px) {
                    margin-top: 4rem;
                    padding: 0;
                }

                @media (min-width: 1440px) {
                    margin-top: 5.5rem;
                }

                @media (min-width: 1680px) {
                    margin-top: 6rem;
                    margin-left: 10rem;
                }

                label[for='choose-date'] {
                    display: inline-block;
                    position: relative;
                    // border: 1px solid red;

                    img {
                        display: inline-block;
                        width: 26px;
                        opacity: 0.4;
                        cursor: pointer;
                    }

                    input {
                        border: 0;
                        position: absolute;
                        top: -25px;
                        left: 0;
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.6);
                        cursor: initial;
                    }
                }

                input.contents {
                    flex: 1;
                    height: 35px;
                    margin: 0 0.5rem;
                    padding: 0 0.35rem;
                    font-size: 15px;
                    color: rgba(0, 0, 0, 0.76);
                    border: 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

                    // @media (min-width: 1366px) {
                    //     font-size: 16px;
                    // }
                }

                .add-button {
                    cursor: pointer;
                }
            }
        }

        &.list {
            @media (min-width: 768px) {
                overflow-y: scroll;
            }

            .date-board {
                display: none;
                align-items: flex-end;
                color: #1a1a1a;
                padding: 3rem 1rem 1.5rem;

                @media (min-width: 768px) {
                    display: flex;
                    font-size: 20px;
                    padding: 4rem 2rem 2rem;
                }

                @media (min-width: 1024px) {
                    padding: 5rem 2rem 2.25rem;
                }

                @media (min-width: 1366px) {
                    font-size: 22px;
                    padding: 6rem 5rem 2.5rem;
                }

                @media (min-width: 1680px) {
                    max-width: 910px;
                    padding: 6rem 0 3rem;
                }

                .year {
                    opacity: 0.8;
                }

                .month {
                    font-size: 32px;
                    margin-left: 1rem;
                }
            }

            ul {
                max-width: 400px;
                padding: 0 0.75rem 1.5rem;

                @media (min-width: 768px) {
                    padding: 0 1rem 2rem;
                }

                @media (min-width: 1024px) {
                    padding: 0 1.25rem 2.25rem;
                }

                @media (min-width: 1366px) {
                    padding: 0 3.5rem 2.5rem;
                }

                @media (min-width: 1680px) {
                    max-width: 450px;
                    padding: 0 0 3rem;
                }

                li {
                    display: flex;
                    align-items: center;
                    padding: 1rem 0;
                    border-bottom: 1px dotted rgba(0, 0, 0, 0.1);

                    &:last-child {
                        border-bottom: 0;
                    }

                    @media (min-width: 1366px) {
                        min-width: 400px;
                    }
                    .date {
                        min-width: 32.5px;
                    }

                    input.description {
                        flex: 1;
                        height: 35px;
                        font-size: 15px;
                        margin: 0 0.5rem;
                        padding: 0 0.35rem;
                        border: 0;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                        background-color: rgba(255, 255, 255, 1);

                        &.active {
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            border-radius: 4px;
                        }
                    }

                    .controls {
                        display: flex;

                        img {
                            cursor: pointer;
                        }

                        .revise {
                            margin-right: 0.3rem;
                            .check {
                                display: none;
                            }
                            &.active {
                                .edit {
                                    display: none;
                                }
                                .check {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
