article.admin-basic-form {
    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        label {
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
            // width: 80%;
            flex: 1;
            height: 30.59px;

            span {
                margin-right: 0.5rem;
                margin-top: -2px;
            }

            div {
                height: 16px;
                border-right: 1px solid rgba(0, 0, 0, 0.5);
                margin-right: 0.5rem;
            }

            input {
                flex: 1;
                height: 100%;
                border: 0;
                font-size: 15px;
            }
        }

        select {
            @include customSelect();
            width: 20%;
            min-width: 100px;
            padding: 0.3rem 2.5rem 0.3rem 0.5rem;
            margin-left: 1rem;
        }
    }

    .edit-box {
        min-height: 500px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        margin-bottom: 1.5rem;
    }
}
