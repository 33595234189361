main#admin {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  padding: 0;

  @media (min-width: 1024px) {
    flex-direction: row;
    min-height: 100vh;
  }

  nav {
    position: relative;
    z-index: 2;
    width: 100%;
    max-height: 50px;
    overflow: hidden;
    transition: 0.4s;

    &.active {
      max-height: 500px;

      .menu {
        div {
          background-color: $representColor;
        }
      }
    }

    @media (min-width: 768px) {
      max-height: 60px;
    }

    @media (min-width: 1024px) {
      max-width: 250px;
      max-height: 100%;
      box-shadow: 1px 0 8px rgba(0, 0, 0, 0.1);

      &.active {
        max-height: 100%;
      }
    }

    @media (min-width: 1440px) {
      max-width: 300px;
    }

    .mobile {
      position: relative;

      @media (min-width: 1024px) {
        display: none;
      }

      figure {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0.8rem;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;

        @media (min-width: 768px) {
          top: 1rem;
        }

        img {
          width: 20px;
          height: 100%;
          object-fit: contain;
          margin-top: 2px;

          @media (min-width: 768px) {
            width: 25px;
          }
        }

        figcaption {
          font-size: 1.1rem;
          padding: 0 0 0 0.5rem;

          @media (min-width: 768px) {
            font-size: 1.3rem;
          }
        }
      }

      .menu {
        position: absolute;
        top: 0.8rem;
        right: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 22px;
        height: 24px;
        cursor: pointer;

        @media (min-width: 768px) {
          top: 1.2rem;
          right: 2rem;
          transform: scale(1.2);
        }

        div {
          width: 6px;
          height: 6px;
          border: 1px solid $representColor;
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 76px 1rem 1rem;
        font-size: 14px;

        @media (min-width: 768px) {
          padding: 86px 2rem 1rem;
          font-size: 15px;
        }

        li {
          width: 50%;
          text-align: center;
          margin-bottom: 2rem;

          a {
            color: rgba(0, 0, 0, 0.5);

            &.active {
              color: rgba(0, 0, 0, 1);
            }
          }
        }
      }
    }

    .desk {
      display: none;
      @media (min-width: 1024px) {
        display: block;

        img {
          width: 150px;
          margin: 3rem auto 6rem;

          @media (min-width: 1366px) {
            margin: 5rem auto 6rem;
          }
        }

        ul {
          li {
            text-align: center;
            margin-bottom: 3rem;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: rgba(0, 0, 0, 0.5);

              &.active {
                color: rgba(0, 0, 0, 1);
              }
            }
          }
        }
      }
    }

    p.logout {
      position: absolute;
      top: 1.1rem;
      left: 1rem;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;

      @media (min-width: 768px) {
        top: 1.6rem;
        left: 2rem;
      }

      @media (min-width: 1024px) {
        top: auto;
        left: 50%;
        bottom: 2.5rem;
        transform: translate(-50%, 0);
      }

      @media (min-width: 1366px) {
        top: auto;
        left: 50%;
        bottom: 5rem;
        transform: translate(-50%, 0);
      }
    }
  }

  .contents {
    position: relative;
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    & > .container {
      padding: 3rem 1rem;

      @media (min-width: 768px) {
        padding: 5rem 2rem;
      }

      @media (min-width: 1024px) {
        padding: 6rem 2rem;
      }

      @media (min-width: 1440px) {
        padding: 7rem 7.19rem;
      }

      @media (min-width: 1680px) {
        max-width: 910px;
        padding: 7rem 0;
        margin-left: 10rem;
      }

      @import './notice-edit', './employ', './member', './member-edit', './sns',
        './schedule';
    }
  }
}
