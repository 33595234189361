header {
    position: fixed;
    z-index: 999;
    width: 100%;
    box-shadow: 1px 0 18px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    @media (min-width: 1024px) {
        position: relative;
    }

    .container {
        width: 100%;
        margin: 0 auto;

        &.mobile {
            height: 50px;
            display: flex;
            align-items: center;

            @media (min-width: 768px) {
                height: 70px;
            }

            @media (min-width: 1024px) {
                display: none;
            }

            figure {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                // z-index: 1;

                .home {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);

                    & > * {
                        display: flex;
                        align-items: center;
                    }

                    img {
                        display: inline-block;
                        width: 100%;
                        max-width: 20px;
                        object-fit: contain;
                        margin-top: 2px;
                    }

                    span {
                        font-size: 1.1rem;
                        padding: 0 0.5rem;
                    }
                }
            }

            nav {
                position: fixed;
                top: 0;
                right: 0;
                transition: 0.5s;
                transform: translateX(100%);
                width: 100%;
                height: 100vh;
                overflow: scroll;
                -webkit-overflow-scrolling: touch;
                padding: 0 2rem 5rem;
                background-color: white;

                @media (min-width: 1024px) {
                    position: relative;
                    height: 50px;
                }

                &.active {
                    transform: translateX(0);
                }

                ul {
                    padding-top: 5rem;
                    // border: 1px solid red;

                    li {
                        position: relative;
                        margin-bottom: 2rem;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                        &:last-child {
                            margin-bottom: 0;
                            border-bottom: none;
                        }

                        div {
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 2rem;
                            margin-left: 3rem;

                            p {
                                width: 50%;
                                margin-bottom: 2.25rem;
                                font-size: 14px;
                                opacity: 0.7;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .hamburger {
                position: fixed;
                right: 1rem;
                z-index: 1;

                @media (min-width: 1024px) {
                    display: none;
                }
            }
        }

        &.desk {
            display: none;
            height: 200px;

            @media (min-width: 1024px) {
                display: block;
            }

            figure {
                position: relative;
                z-index: 1;
                height: 150px;
                background-color: white;
                border-bottom: 1px solid rgba(211, 211, 211, 1);

                .wrap {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    max-width: 1024px;
                    margin: 0 auto;

                    .home {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 200px;

                        img {
                            width: 100%;
                            object-fit: contain;
                        }
                    }

                    .sns-icons-wrap {
                        position: absolute;
                        top: 50%;
                        right: 8%;
                        transform: translate(0, -50%);
                        display: flex;

                        a.instagram {
                            width: 25px;
                            margin-right: 2rem;
                        }

                        a.youtube {
                            width: 35px;
                        }
                        img {
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            nav {
                position: relative;
                height: 50px;

                .main {
                    position: relative;
                    z-index: 1;
                    height: 100%;
                    background-color: white;

                    ul {
                        position: relative;
                        width: 100%;
                        max-width: 1024px;
                        height: 100%;
                        margin: 0 auto;
                        display: flex;
                    }

                    li {
                        position: relative;
                        z-index: 1;
                        flex: 1;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;
                        cursor: pointer;
                        font-size: 17px;
                    }
                }
                .sub {
                    position: absolute;
                    z-index: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    transition: 0.5s;
                    padding: 2rem 0;
                    background-color: rgba(90, 75, 58, 0.75);

                    ul.container {
                        display: flex;
                        max-width: 1024px;
                        margin: 0 auto;

                        li.wrap {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            p {
                                margin-bottom: 1.5rem;
                                cursor: pointer;
                                color: rgba(255, 255, 255, 0.78);
                                font-size: 15px;
                                line-height: 1.8;
                                transition-property: color;
                                transition-duration: 0.35s;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                &:hover {
                                    color: rgba(255, 255, 255, 1);
                                    background-image: linear-gradient(#ffffff, #ffffff);
                                    background-position: 0.05em 94%;
                                    background-repeat: no-repeat;
                                    background-size: calc(100% - 0.1em) 0.075em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
