html {
    scroll-behavior: smooth;
    /* Microsoft scrollbar none */
    -ms-overflow-style: none;

    &,
    & * {
        /* window Firefox scrollbar none */
        scrollbar-width: none;
    }
}

::-webkit-scrollbar {
    /* Chrome, Safari scrollbar none */
    display: none;
}

body {
    overscroll-behavior-y: none;

    * {
        margin: 0;
        padding: 0;
        outline: none;
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    ul,
    ol {
        list-style-type: none;

        li:last-child {
            margin-bottom: 0;
        }
    }

    img {
        display: block;
    }

    label {
        color: rgba(0, 0, 0, 0.54);
    }

    input,
    textarea {
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(112, 112, 112, 0.4);
            opacity: 1;
            font-size: 0.9rem;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(112, 112, 112, 0.4);
            font-size: 0.9rem;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(112, 112, 112, 0.4);
            font-size: 0.9rem;
        }
    }

    input[type='text']::-ms-clear {
        display: none;
    }

    @import './custom_common';
}
