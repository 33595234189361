section#login {
    form {
        @include centered();
        top: 45%;
        width: 100%;
        max-width: 400px;
        padding: 0 1rem;
        font-size: 14px;
        // border: 1px solid black;

        @media (min-width: 768px) {
            font-size: 16px;
        }

        label {
            display: flex;
            align-items: center;
            height: 30px;
            margin-bottom: 1.5rem;

            span {
                order: 1;
                width: 60px;
                margin-right: 0.8rem;
                cursor: pointer;
            }

            input {
                order: 2;
                flex: 1;
                height: 100%;
                font-size: 15px;
                padding: 0 0.5rem;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.8);

                & + span {
                    color: black;
                }

                &:placeholder-shown {
                    order: 2;
                    flex: 1;
                    height: 100%;
                    font-size: 15px;
                    padding: 0 0.5rem;
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

                    &:focus {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.6);

                        & + span {
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }

                    & + span {
                        color: rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }

        button {
            width: 100%;
            border: none;
            background-color: $representColor;
            color: white;
            padding: 0.4rem 0;
            margin: 1rem 0;
            font-size: 15px;
            cursor: pointer;
        }

        // .find {
        //     font-size: 12px;

        //     span {
        //         opacity: 0.4;
        //         cursor: pointer;

        //         &:first-child {
        //             padding-right: 0.7rem;
        //             margin-right: 0.7rem;
        //             border-right: 1px solid rgba(0, 0, 0, 0.1);
        //         }

        //         &:hover {
        //             opacity: 1;
        //         }
        //     }
        // }
    }
}
