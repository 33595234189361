.popup-container {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.active {
    display: block;
  }

  .popup-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    background-color: white;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.6);
  }

  .popup-header,
  .popup-footer {
    min-height: 50px;
    padding: 1.5rem;
  }

  .popup-header {
    position: relative;
    padding-top: 1.5rem;
    text-align: center;
    line-height: 1.4;

    b {
      font-weight: bold;
    }
  }

  .popup-body {
    padding: 2rem 1.5rem 2.5rem;
    line-height: 1.5;
    font-size: 0.9em;
  }

  .popup-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .check-group {
      position: relative;
      display: flex;
      align-items: center;

      input[type='checkbox']:checked ~ img {
        opacity: 1;
      }

      input[type='checkbox']:checked ~ label .box {
        background-color: rgba(0, 0, 0, 0);
      }

      input[type='checkbox']:checked ~ label span {
        color: rgba(0, 0, 0, 1);
      }

      img {
        position: absolute;
        top: -5px;
        z-index: 1;
        opacity: 0;
      }

      label {
        display: flex;
        align-items: center;
        cursor: pointer;

        .box {
          position: relative;
          z-index: 2;
          width: 15px;
          height: 15px;
          border: 1px solid rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.05);
        }

        span {
          font-size: 0.85em;
          padding-left: 0.5em;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
    button.close {
      border: 0;
      background-color: white;
      font-size: 0.9em;
      cursor: pointer;
    }
  }

  @media (min-width: 768px) {
    .popup-wrapper {
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      border-radius: 8px;
      max-width: 550px;
      height: auto;
    }

    .popup-body {
      font-size: 0.95em;
    }
  }
}
