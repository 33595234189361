section#introduction {
    width: 100vw;
    overflow-x: hidden;

    article.headline {
        height: 150px;

        .wrap {
            @include wrapMaxCenter(1024px);

            height: 100%;
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media (min-width: 768px) {
                flex-direction: row;
                padding: 0 100px;
                align-items: center;
            }

            h3 {
                font-size: 16px;
                text-align: center;
                margin-bottom: 2rem;

                @media (min-width: 768px) {
                    font-size: 19px;
                    margin-bottom: 0;
                }
            }

            nav {
                font-size: 14px;

                @media (min-width: 768px) {
                    flex: 1;
                    padding-left: 100px;
                    font-size: 16px;
                }

                ul {
                    display: flex;
                    justify-content: space-between;

                    li {
                        flex: 1;
                        border-right: 1px solid gray;
                        color: rgba(0, 0, 0, 0.68);
                        text-align: center;

                        &:hover {
                            color: rgba(0, 0, 0, 1);
                        }

                        &:last-child {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    article.comment {
        padding: 4rem 0;
        background-color: rgba(248, 247, 245, 1);

        @media (min-width: 768px) {
            padding: 7rem 0;
        }

        .wrap {
            @include wrapMaxCenter(1024px);

            p {
                text-align: center;
                line-height: 1.8;
                font-size: 15px;
                color: $representColor;

                @media (min-width: 768px) {
                    font-size: 18px;
                }

                &:last-child {
                    @media (min-width: 768px) {
                        margin-right: -65px;
                    }

                    span {
                        background-color: $representColor;
                        color: white;
                        padding: 0 0.2rem;
                    }
                }

                img {
                    display: none;

                    @media (min-width: 768px) {
                        max-width: 15px;
                        display: inline-block;
                        margin: 0 0.5rem;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        &.two {
            background-color: rgb(238, 241, 243);

            .wrap {
                div {
                    &.mobile {
                        @media (min-width: 768px) {
                            display: none;
                        }
                    }

                    &.desktop {
                        display: none;
                        @media (min-width: 768px) {
                            display: block;
                        }
                    }

                    p {
                        color: rgb(0, 57, 81);

                        &:last-child {
                            margin-right: 0;
                        }

                        strong {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    article.hilltop_is,
    article.strategy,
    article.system,
    article.inside {
        color: $representColor;
        padding: 5rem 0;

        @media (min-width: 768px) {
            padding: 7rem 0;
        }

        .wrap {
            @include wrapMaxCenter(1024px);

            hgroup {
                text-align: center;
                margin-bottom: 3rem;

                @media (min-width: 768px) {
                    margin-bottom: 4rem;
                }

                h2 {
                    font-weight: bold;

                    @media (min-width: 768px) {
                        font-size: 30px;
                    }
                }

                h5 {
                    opacity: 0.5;
                    line-height: 1.5;

                    @media (min-width: 768px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    article.hilltop_is {
        .wrap {
            hgroup {
                h5 {
                    margin-right: -40px;

                    @media (min-width: 768px) {
                        margin-right: -50px;
                    }
                }
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                // border: 1px solid darkgrey;

                @media (min-width: 768px) {
                    padding: 0 4rem;
                }
                @media (min-width: 1024px) {
                    padding: 0 100px;
                }

                li {
                    width: 50%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    margin-bottom: 3rem;

                    // border: 1px solid darkgrey;

                    @media (min-width: 768px) {
                        width: auto;
                        margin-bottom: 0;
                    }

                    figure {
                        text-align: center;

                        // border: 1px solid darkorange;

                        img {
                            max-width: 90px;
                            margin: 0 auto;
                            margin-bottom: 1.5rem;

                            &.heart {
                                max-width: 70px;
                            }

                            @media (min-width: 768px) {
                                max-width: 120px;
                                margin-bottom: 2rem;

                                &.pray {
                                    max-width: 100px;
                                }
                                &.heart {
                                    max-width: 80px;
                                }
                            }
                        }
                        figcaption {
                            display: inline-block;
                            margin: 0 auto;
                            background-color: $representColor;
                            color: white;
                            padding: 0.3rem 0.5rem;
                            font-size: 15px;

                            @media (min-width: 768px) {
                                font-size: 17px;
                            }

                            br {
                                @media (min-width: 768px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    article.strategy {
        .wrap {
            hgroup {
                h5 {
                    margin-right: -60px;

                    @media (min-width: 768px) {
                        margin-right: -80px;
                    }
                }
            }

            .box {
                position: relative;
                width: 300px;
                height: 300px;
                margin: 0 auto;
                color: rgb(0, 57, 81);
                // border: 1px dotted green;

                @media (min-width: 768px) {
                    width: 450px;
                    height: 450px;
                }

                .big_circle {
                    width: 100%;
                    height: 100%;
                    border: 1px solid rgb(0, 57, 81);
                    border-radius: 50%;
                }

                figure.circle {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 110px;
                    height: 110px;
                    border-radius: 50%;
                    background-color: white;
                    // box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
                    text-align: center;

                    @media (min-width: 768px) {
                        width: 150px;
                        height: 150px;
                    }

                    img {
                        margin: 0 auto;
                        margin-bottom: 0.5rem;

                        @media (min-width: 768px) {
                            margin-bottom: 1rem;
                        }
                    }

                    figcaption {
                        line-height: 1.3;
                        font-size: 15px;

                        @media (min-width: 768px) {
                            font-size: 17px;
                        }
                    }

                    &.graph {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        box-shadow: none;

                        img {
                            width: 65px;

                            @media (min-width: 768px) {
                                width: 90px;
                            }
                        }
                    }

                    &.location {
                        top: 0;
                        left: 0;
                        transform: translate(-30%, 30%);

                        img {
                            width: 30px;

                            @media (min-width: 768px) {
                                width: 35px;
                            }
                        }
                    }

                    &.list {
                        top: 0;
                        right: 0;
                        transform: translate(30%, 30%);

                        img {
                            width: 50px;

                            @media (min-width: 768px) {
                                width: 60px;
                            }
                        }
                    }

                    &.monkey_wrench {
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%, 40%);

                        img {
                            width: 45px;

                            @media (min-width: 768px) {
                                width: 55px;
                            }
                        }
                    }
                }
            }
        }
    }

    article.system {
        border-top: 1px solid rgba(112, 112, 112, 0.1);

        ul {
            color: black;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (min-width: 768px) {
                flex-direction: row;
            }

            li {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                white-space: nowrap;

                @media (min-width: 768px) {
                    border-right: 1px solid rgba(0, 0, 0, 0.68);
                    height: 24px;
                }

                h2 {
                    font-weight: bold;
                    display: inline-block;
                    margin-right: 0.5rem;
                    font-size: 20px;

                    @media (min-width: 768px) {
                        font-size: 24px;
                    }
                }

                span {
                    font-size: 14px;
                    align-self: flex-end;
                    opacity: 0.86;

                    @media (min-width: 768px) {
                        font-size: 15px;
                    }
                }

                .divide {
                    width: 100%;

                    @media (min-width: 768px) {
                        display: none;
                    }

                    .line {
                        width: 50px;
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                        margin: 1.5rem auto;
                    }
                }

                &:last-child {
                    flex-direction: column;
                    flex-wrap: nowrap;
                    border: none;
                    margin-bottom: 0;

                    @media (min-width: 768px) {
                        align-items: flex-start;
                        text-align: left;
                        padding-left: 1.5rem;
                        margin-top: 13px;
                    }

                    @media (min-width: 1024px) {
                        padding-left: 3rem;
                    }

                    h2 {
                        display: block;
                    }

                    span {
                        align-self: auto;
                        line-height: 2;
                    }
                }
            }
        }
    }

    article.inside {
        background-color: rgb(247, 247, 247);

        .plan {
            position: relative;
            max-width: 768px;
            padding: 0 0.5rem;
            margin: 0 auto;
            // border: 1px solid red;
            *:active {
                -webkit-tap-highlight-color: transparent;
            }

            @media (min-width: 768px) {
                padding: 0 1rem;
            }

            .floor-plan {
                img {
                    width: 100%;
                }
            }

            ul.one,
            .two,
            .three,
            .four,
            .five {
                position: absolute;
                // border: 1px solid green;

                img {
                    width: 20px;

                    @media (min-width: 768px) {
                        width: 26px;
                    }
                }
            }

            ul.one {
                top: 8%;
                left: 5%;
                width: 64%;
                height: 49%;
                // border: 1px solid blue;

                li {
                    position: absolute;
                    cursor: pointer;

                    &:first-child {
                        top: 50%;
                        left: 29%;
                    }

                    // &:nth-child(2),
                    // &:nth-child(3),
                    // &:last-child {
                    //     border: 1px solid red;
                    // }

                    &:nth-child(2) {
                        width: 23.5%;
                        height: 50%;
                    }

                    &:nth-child(3) {
                        width: 16%;
                        height: 100%;
                        left: 23%;
                    }

                    &:last-child {
                        width: 61%;
                        height: 28%;
                        bottom: 0;
                        right: 0;
                    }
                }
            }

            .two,
            .three,
            .four,
            .five {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            .two {
                width: 15%;
                height: 60%;
                top: 32%;
                left: 5%;
            }

            .three {
                width: 22%;
                height: 35%;
                top: 57%;
                left: 20%;
            }

            .four {
                width: 27%;
                height: 35%;
                top: 57%;
                left: 42%;
            }

            .five {
                width: 26%;
                height: 49%;
                top: 43%;
                left: 68.8%;
            }
        }
    }
}
