section.main_schedule {
    background-color: rgba(248, 248, 248, 1);

    .container {
        @include wrapMaxCenter(900px);
        padding-bottom: 6rem;

        @media (min-width: 768px) {
            padding-bottom: 10rem;
        }

        hgroup {
            text-align: center;
            padding: 4rem 0 3rem;

            @media (min-width: 768px) {
                padding: 6rem 0 4rem;
            }

            @media (min-width: 1024px) {
                padding: 8rem 0 6rem;
            }

            h1 {
                color: $representColor;
                font-size: 24px;
                font-weight: bold;

                @media (min-width: 1024px) {
                    font-size: 36px;
                }
            }
        }
    }
}
