footer {
    position: relative;
    padding: 3rem 0;
    background-color: rgba(224, 224, 224, 0.1);
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
    border-top: 2px solid rgba(112, 112, 112, 0.1);

    @media (min-width: 768px) {
        font-size: 15px;
    }

    @media (min-width: 1024px) {
        padding: 6rem 0;
    }

    ul {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        text-align: center;
        line-height: 2;
        padding: 0 1rem;

        li {
            br {
                display: none;

                @media (max-width: 767px) {
                    display: block;
                }
            }

            img.admin-move {
                display: inline-block;
                width: 18px;
                margin-left: 0.5rem;
            }
        }

        li:first-child {
            .devicon {
                display: flex;
                justify-content: space-around;
                max-width: 120px;
                margin: 0 auto;
                margin-bottom: 2rem;

                a:first-child {
                    width: 30px;
                    img {
                        width: 100%;
                    }
                }

                a:last-child {
                    width: 40px;
                    img {
                        width: 100%;
                    }
                }

                @media (min-width: 1024px) {
                    display: none;
                }
            }
        }

        li:last-child {
            display: flex;
            justify-content: center;
            margin-top: 3rem;

            img {
                width: 100%;
                max-width: 100px;
                height: 100%;
            }
        }
    }
}
