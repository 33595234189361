section#banner {
    position: relative;
    // height: 330px;
    background-color: #194663;
    background-image: url('/assets/images/banner/banner_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    @media (min-width: 768px) {
        height: 400px;
    }
    @media (min-width: 1024px) {
        height: 500px;
    }

    .mobile {
        @media (min-width: 768px) {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    .desk {
        display: none;

        @media (min-width: 768px) {
            display: block;
        }

        .wrap {
            z-index: 1;
            @include centered();
            top: 35%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            .eng-math {
                font-size: 50px;
                font-weight: bold;
                color: white;

                @media (min-width: 1024px) {
                    font-size: 80px;
                }
            }

            img.uplands {
                width: 100%;
                max-width: 150px;
                height: 100%;
                margin: 0 1rem -20px 1.5rem;

                @media (min-width: 1024px) {
                    max-width: 200px;
                }
            }

            .hilltop {
                background-color: white;
                height: 70px;
                display: flex;
                align-items: center;
                margin-bottom: -11px;
                padding: 0 0.5rem;

                @media (min-width: 1024px) {
                    height: 100px;
                }

                p {
                    font-size: 50px;
                    font-weight: bold;
                    color: #194663;
                    margin-top: -3px;

                    @media (min-width: 1024px) {
                        font-size: 80px;
                    }
                }
            }
        }

        img.comment {
            @include centered();
            top: 75%;
            width: 100%;
            max-width: 768px;
            padding: 0 4.5rem;

            @media (min-width: 1024px) {
                max-width: 990px;
                padding: 0 0.5rem 0 1rem;
            }
        }
    }
}
