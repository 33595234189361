main {
    position: relative;
    min-height: 100vh;
    padding-top: 50px;
    background-color: rgba(255, 255, 255, 1);

    @media (min-width: 768px) {
        padding-top: 70px;
    }

    @media (min-width: 1024px) {
        min-height: calc(100vh - 200px);
        padding-top: 0;
    }
}
