hgroup.common-title {
    &.admin {
        border-bottom: none;
        padding-bottom: 0;

        h3 {
            font-size: 18px;

            @media (min-width: 768px) {
                font-size: 20px;
            }

            @media (min-width: 1440px) {
                font-size: 22px;
            }
        }
    }
}
