section.member {
    article {
        @media (min-width: 1440px) {
            width: 120%;
            transform: translateX(-8%);
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media (min-width: 1440px) {
                justify-content: flex-start;
            }

            li {
                position: relative;
                width: 100%;
                min-height: 450px;
                box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
                margin-bottom: 2rem;
                overflow: hidden;

                @media (min-width: 768px) {
                    width: 48%;
                    // height: 400px;
                }

                @media (min-width: 1440px) {
                    width: 32%;
                    margin-right: 2%;

                    $var: 3;
                    @for $i from 1 through 9 {
                        &:nth-child(#{$var}) {
                            margin-right: 0;
                            $var: $var + 3;
                        }
                    }
                }

                &:first-child {
                    transition: 0.3s;

                    @media (max-width: 767px) {
                        min-height: 45px;
                    }

                    &:hover {
                        box-shadow: 1px 1px 8px rgba(137, 207, 180, 0.4);

                        img {
                            opacity: 0.6;
                        }
                    }

                    a {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        @include centered();
                        width: 32px;
                        opacity: 0.3;
                        transition: 0.3s;
                    }
                }

                img {
                    display: inline-block;
                }

                .control {
                    display: flex;
                    justify-content: space-between;
                    padding: 0.7rem;

                    a {
                        cursor: initial;
                    }

                    img {
                        opacity: 0.3;

                        &:hover {
                            opacity: 0.8;
                            cursor: pointer;
                        }
                    }

                    .close-icon {
                        width: 28px;
                    }
                }

                .info {
                    width: 70%;
                    height: calc(100% - 50px);
                    padding-bottom: 2rem;
                    margin: 0 auto;

                    h3 {
                        font-size: 16px;
                        text-align: center;
                        margin-bottom: 2rem;

                        @media (min-width: 768px) {
                            font-size: 19px;
                        }
                    }

                    .bundle {
                        display: flex;
                        margin-bottom: 1.5rem;
                        font-size: 14px;

                        @media (min-width: 768px) {
                            font-size: 16px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                            .description {
                                line-height: 1.3;
                            }
                        }

                        .title {
                            min-width: 90px;
                        }

                        .description {
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
            }
        }
    }
}
